import { useState } from 'react'

import { captureException } from '@sentry/browser'
import { TableName } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import ClientForm from 'src/components/SuperAdmin/Client/ClientForm'
import useACL from 'src/lib/hooks/ACL/useACL'

import { HubClientType } from '../EditClientCell'

const CREATE_CLIENT_MUTATION = gql`
  mutation CreateClientMutation($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }
`

const GET_PRODUCTS = gql`
  query getStripeProducts {
    products: productFeaturesBoard {
      id
      name
    }
  }
`

const NewClient = () => {
  const [createClient, { loading, error }] = useMutation(CREATE_CLIENT_MUTATION)
  const [products, setProducts] = useState([])

  useQuery(GET_PRODUCTS, {
    onCompleted: ({ products }) => {
      setProducts(products)
    },
  })

  const { AccessControlList, saveACL } = useACL({
    resourceType: 'Client' as TableName,
    contentPrivateDefault: true,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const onSave = async (input: HubClientType) => {
    const castInput = Object.assign(input, {})

    try {
      const client = await createClient({ variables: { input: castInput } })
      const clientId = client.data.createClient.id
      await saveACL({ resourceId: clientId })
      toast.success('Client created')
      navigate(routes.clients())
    } catch (error) {
      toast.error('Error creating client')
      captureException(error)
    }
  }

  return (
    <div className="rw-segment">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">New Client</h2>
      </header>
      <div className="rw-segment-main">
        <ClientForm
          client={null}
          onSave={onSave}
          loading={loading}
          error={error}
          products={products}
        >
          <AccessControlList />
        </ClientForm>
      </div>
    </div>
  )
}

export default NewClient
