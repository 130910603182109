import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import NewClient from 'src/components/SuperAdmin/Client/NewClient'

const NewClientPage = () => {
  return (
    <>
      <Metadata
        title="Client Management - New Client"
        description="Client Management - New Client"
      />
      <PageHeader title="Client Management - New Client" />
      <NewClient />
    </>
  )
}

export default NewClientPage
